import React, { useContext, useState, useEffect } from 'react'
import Context from '../../context/Context';
import { URL, URL_FRONT } from "../../service/Service";
import { useParams } from 'react-router-dom';
import { editFormElement } from '../../context/forms/FormsAction';
import Header from '../TitleAndSubtitle';


const RatingEditWizard = ({ elementInfo, userRole }) => {
    const { idform, idformelement } = useParams()
    const { dispatch } = useContext(Context);
    const [label, setLabel] = useState(elementInfo.label);
    const [required, setRequired] = useState(elementInfo.required);

    // Track initial values to check if element should be deleted when clicking on cancel button
    const [initialLabel] = useState(elementInfo.label);
    const [initialRequired] = useState(elementInfo.required);

    useEffect(() => {
        setLabel(elementInfo.label)
        setRequired(elementInfo.required)
    }, [elementInfo.label, elementInfo.required]);


    const [success, setSuccess] = useState(false); // new state variable

    const updateHandler = async () => {
        const url = `${URL}/api/forms/${idform}/formelements/${idformelement}`;
        const request = {
            method: 'PATCH',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ required, label }),
        };

        const response = await fetch(url, request);
        if (response.status === 200) {
            setSuccess(true);
        }

        editFormElement(url, request, dispatch);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        updateHandler();
        setTimeout(() => {
            if (userRole === 'ADMIN') {
                window.location.href = `${URL_FRONT}/admin/forms/${idform}/formwizard/1`;
            } else {
                window.location.href = `${URL_FRONT}/forms/${idform}/formwizard/1`;
            }
        }, 1000); // 1000 milliseconds = 1 second
    };

    const deleteElementHandler = (elementId) => {
        const url = `${URL}/api/forms/${idform}/formelements/${elementId}`;
        const request = {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
        };
        editFormElement(url, request, dispatch);
    };

    const handleCancel = (event) => {
        event.preventDefault();
        // If no information was ever entered, delete the element
        if (initialLabel === '' && initialRequired === false) {
            deleteElementHandler(idformelement);
        }
        window.history.back();
    };

    return (
        <div className='p-5'>
            <div className='mb-5'></div>
            <div className="flex flex-col md:flex-row justify-between items-center">
                <Header title="Editar Elemento" />
            </div>

            <form onSubmit={handleSubmit} className=" bg-white p-6 rounded-lg shadow-md">
                <div className="mb-4">
                    <label for="default-input" className="p-2.5 ext-sm font-bold text-gray-900">Legenda</label>
                    <input
                        className="mt-1 w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" type="text"
                        onChange={e => setLabel(e.target.value)}
                        value={label}
                    />
                </div>
                <div className="mb-10">
                    <label className="flex items-center">
                        <span className="font-bold text-gray-900 mr-3 ml-2.5">Campo Obrigatório:</span>
                        <input
                            type="checkbox"
                            className="form-checkbox mr-2"
                            onChange={e => setRequired(e.target.checked)}
                            defaultChecked={required}

                        />
                    </label>
                </div>
                <div className="flex justify-end">
                    <button className="mr-4 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded" onClick={handleCancel}>
                        Cancelar
                    </button>
                    <button className="mr-4 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded">
                        Guardar
                    </button>
                </div>
            </form>
            {success && (
                <div className="fixed bottom-0 right-0 mb-4 mr-4 animate-slide-up">
                    <div className="mt-10 relative flex flex-col sm:flex-row sm:items-center bg-white shadow rounded-md py-5 pl-6 pr-8 sm:pr-6">
                        <button className="absolute top-0 right-0 px-2 py-1" type='button' onClick={() => setSuccess(false)}>x</button>
                        <div className="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                            <div className="text-green-500">
                                <svg className="w-6 sm:w-5 h-6 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            </div>
                            <div className="text-sm font-medium ml-3">Sucesso:</div>
                        </div>
                        <div className="text-sm tracking-wide text-gray-500 mt-4 sm:mt-0 sm:ml-4">Dados guardados com sucesso!</div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default RatingEditWizard