import React, { useContext, useEffect, useState } from 'react';
import { fetchReplies, updateReference } from '../../context/forms/FormsAction';
import { fetchReference } from '../../context/forms/FormsAction';

import Context from '../../context/Context';
import { useParams } from 'react-router-dom';
import { URL } from "../../service/Service";
import Breadcrumb from '../../components/Breadcrumb';
import Spinner from '../../components/Spinner';
import Header from '../../components/TitleAndSubtitle';
import { format, parse } from 'date-fns';


const Replies = () => {
    const { idform } = useParams()
    const { state, dispatch } = useContext(Context);
    const { loading, error, data } = state.replies;
    const url = `${URL}/api/forms/${idform}/replies`;
    const [replies, setReplies] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedReply, setSelectedReply] = useState(null);
    const referenceStatusOptions = ['PAID', 'UNPAID', 'CANCELLED', 'REFUNDED'];
    const [isItemsModalOpen, setIsItemsModalOpen] = useState(false);
    const [itemsToShow, setItemsToShow] = useState({});

    //////////////////////SEARCH AND PAGINATION///////////////////////////////
    const [searchTerm, setSearchTerm] = useState('');


    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const toggleItemsModal = (items = {}) => {
        setItemsToShow(items);
        setIsItemsModalOpen(!isItemsModalOpen);
    };

    const handleCopy = (id) => {
        navigator.clipboard.writeText(id).then(() => {
            // Optionally, you can show a success message or feedback
            console.log(`Copied ID: ${id}`);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };


    useEffect(() => {
        const isSmallScreen = window.innerHeight <= 600;
        setRowsPerPage(isSmallScreen ? 5 : 10);
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // reset page number when search term changes
    };

    const filteredformsMap = replies.filter((item) => {
        return item.id.toLowerCase().includes(searchTerm.toLowerCase())
    });

    const lastRowIndex = currentPage * rowsPerPage;
    const firstRowIndex = lastRowIndex - rowsPerPage;
    const currentRows = filteredformsMap.slice(firstRowIndex, lastRowIndex);
    const totalPages = Math.ceil(filteredformsMap.length / rowsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const pageNumbers = [];

    // show the first page number
    pageNumbers.push(1);

    for (let i = currentPage - 2; i <= currentPage + 2; i++) {
        if (i > 1 && i < totalPages) {
            pageNumbers.push(i);
        }
    }

    // show the last page number
    pageNumbers.push(totalPages);

    ////////////////////////////////////////////////////////////////////////////////


    const handleReplyClick = (reply) => {
        setSelectedReply(reply);
        setShowModal(true);
    }

    const pages = [
        {
            name: 'Formulários',
            href: `/forms/`,
            current: false
        },
        {
            name: 'Respostas',
            href: `/forms/${idform}/replies`,
            current: true
        },
    ];

    useEffect(() => {
        fetchReplies(url, {}, dispatch);
        setReplies(Object.values(data).reverse())
    }, [dispatch, idform]);

    useEffect(() => {
        if (data && Object.values(data).length > 0) {
            setReplies(Object.values(data).reverse());
        }
    }, [data]);

    useEffect(() => {
        if (data && Object.values(data).length > 0) {
            setReplies(Object.values(data).reverse());
        }
    }, [data]);



    if (loading === true) {
        return (
            <Spinner></Spinner>
        )
    }

    function formatDate(timestamp) {
        try {
            // Define the format of your input timestamp
            const parsedDate = parse(timestamp, 'dd:MM:yyyy HH:mm', new Date());

            // Format the parsed date into your desired output format
            return format(parsedDate, 'dd/MM/yyyy HH:mm');
        } catch (error) {
            console.error('Invalid timestamp:', timestamp);
            return 'Invalid Date';
        }
    }

    function downloadCSV(array) {
        const csvRows = [];

        // Collect and push reply headers
        const replyHeadersCSV = [];
        array.forEach(reply => {
            reply.replyHeaders.forEach(header => {
                if (!replyHeadersCSV.includes(header)) {
                    replyHeadersCSV.push(header);
                }
            });
        });
        const headers = ['Data da Resposta', 'Identificador', 'Método de Pagamento', 'Valor', 'Estado', ...replyHeadersCSV];
        csvRows.push(headers);

        array.forEach(reply => {
            const timestamp = reply.timestamp;
            const formID = reply.formID;
            const paymentMethod = reply.reference.referencePaymentMethod;
            const amount = reply.reference.amount;
            const status = reply.reference.referenceStatus;
            const row = [];

            // Fill in the known values
            row.push(timestamp, formID, paymentMethod, amount, status);

            // Match each header with the corresponding reply field label of each reply field answer
            replyHeadersCSV.forEach(header => {
                const field = reply.replyFields.find(field => field.label.toLowerCase() === header.toLowerCase());
                if (field) {
                    row.push(field.answer); // Include the answer if there is a match
                } else {
                    // Check if the header corresponds to an item and include the quantity if available
                    const quantity = reply.itemsPurchasedAndQuantity[header];
                    if (quantity !== undefined) {
                        row.push(quantity); // Include the quantity if there is a match
                    } else {
                        row.push(""); // Leave empty if there is no match
                    }
                }
            });

            csvRows.push(row); // Include new fields in the CSV row
        });

        const csv = csvRows.map(row => row.map(val => `"${val}"`).join(',')).join('\n');

        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement('a');
        link.setAttribute('href', window.URL.createObjectURL(csvData));
        link.setAttribute('download', 'replies.csv');
        link.style.display = 'none';
        document.body.appendChild(link);

        link.click();
    }

    const handleStatusChange = (referenceID, newStatus, item) => {
        const urlUpdateStatus = `${URL}/api/reference/${referenceID}`;
        const request = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({ status: newStatus }),
        };

        // Update the local replies state first
        setReplies(prevReplies =>
            prevReplies.map(reply =>
                reply.reference.referenceIdToString === referenceID
                    ? { ...reply, reference: { ...reply.reference, referenceStatus: newStatus } }
                    : reply
            )
        );

        // Then update the backend
        updateReference(urlUpdateStatus, request, dispatch);
    };

    return (

        <div className='p-5'>
            <Breadcrumb pages={pages} />
            <div className='mb-5'></div>
            <div className="flex flex-col md:flex-row justify-between items-center">
                <Header title="Respostas" />
                <button className='mt-5 md:mt-0 bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded' onClick={() => downloadCSV(replies)}>Exportar CSV</button>
            </div>
            <div className="my-4">
                <input className="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       type="text" placeholder="Procurar por Identificador" value={searchTerm} onChange={handleSearch} />
            </div>
            <div className="overflow-auto rounded-md">
                <table className="table-auto w-full border">
                    <thead className=''>
                    <tr className="bg-green-cl">
                        <th className="px-4 py-2 text-center text-gray-100">Identificador</th>
                        <th className="px-4 py-2 text-center text-gray-100">Data da Resposta</th>
                        <th className="px-4 py-2 text-center text-gray-100">Método de Pagamento</th>
                        <th className="px-4 py-2 text-center text-gray-100">Valor</th>
                        <th className="px-4 py-2 text-center text-gray-100">Estado</th>
                        <th className="px-4 py-2 text-center text-gray-100">Itens Adquiridos</th>
                        <th className="px-4 py-2 text-center text-gray-100">Respostas</th>
                    </tr>
                    </thead>
                    <tbody className='text-center items-center'>
                    {currentRows.map((item) => (
                        <tr key={item.id}
                            className="border-t border-gray-200 px-4 py-1 justify-center items-center">
                            <td className="px-4 py-1 flex justify-between items-center">
                                <button
                                    className="bg-gray-200 hover:bg-gray-300 text-gray-800 rounded transition duration-200 ease-in-out shadow-sm px-4 py-1 mr-2 flex items-center justify-center"
                                    onClick={() => handleCopy(item.id)}
                                    aria-label={`Copy ID ${item.id}`}
                                    title="Copiar Identificador" // Tooltip text
                                >
                                    <svg
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-6 h-6"
                                    >
                                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                                           strokeLinejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier">
                                            <path
                                                d="M6 11C6 8.17157 6 6.75736 6.87868 5.87868C7.75736 5 9.17157 5 12 5H15C17.8284 5 19.2426 5 20.1213 5.87868C21 6.75736 21 8.17157 21 11V16C21 18.8284 21 20.2426 20.1213 21.1213C19.2426 22 17.8284 22 15 22H12C9.17157 22 7.75736 22 6.87868 21.1213C6 20.2426 6 18.8284 6 16V11Z"
                                                stroke="#1C274C"
                                                strokeWidth="1.5"
                                            ></path>
                                            <path
                                                d="M6 19C4.34315 19 3 17.6569 3 16V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H15C16.6569 2 18 3.34315 18 5"
                                                stroke="#1C274C"
                                                strokeWidth="1.5"
                                            ></path>
                                        </g>
                                    </svg>
                                </button>
                                <div className="text-center mx-auto" title={item.id}>
                                    {item.id}
                                </div>
                            </td>


                            <td className="px-4 py-1 justify-center items-center">{formatDate(item.timestamp)}</td>
                            {/*<td className="px-4 py-1">{item.timestamp}</td>*/}
                            <td className="px-4 py-1 justify-center items-center">
                                {item.reference.referencePaymentMethod === 'MULTIBANCO' && 'Multibanco'}
                                {item.reference.referencePaymentMethod === 'MBWAY' && 'Mb Way'}
                                {item.reference.referencePaymentMethod === 'CREDIT_CARD' && 'Cartão de Crédito'}
                                {item.reference.referencePaymentMethod === 'CASH_PAYMENT' && 'Numerário'}

                            </td>
                            <td className="px-4 py-1 justify-center items-center">
                                {parseFloat(item.reference.amount).toFixed(2).replace(/\./g, ',') + " €"}
                            </td>
                            <td className="px-4 py-1 justify-center items-center">
                                <select
                                    value={item.reference.referenceStatus}
                                    onChange={(e) =>
                                        handleStatusChange(item.reference.referenceIdToString, e.target.value, item)}
                                    className="border border-gray-300 rounded p-1"
                                    style={{ fontWeight: 'bold' }}
                                >
                                    {referenceStatusOptions.map((status) => (
                                        <option key={status} value={status}>
                                            {status === 'PAID' ? 'Pago' : status === 'UNPAID' ? 'Não Pago' : status === 'CANCELLED' ? 'Cancelado' : 'Reembolsado'}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td className="px-4 py-1 justify-center items-center">
                                <button
                                    className="bg-gray-200 hover:bg-gray-300 text-gray-800 rounded transition duration-200 ease-in-out shadow-sm px-4 py-1 justify-center items-center"
                                    onClick={() => toggleItemsModal(item.itemsPurchasedAndQuantity)}
                                    aria-label="Mostrar itens adquiridos"
                                >
                                    <svg
                                        fill="#243f3b"
                                        version="1.1"
                                        id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 902.86 902.86"
                                        xmlSpace="preserve"
                                        className="w-6 h-6" // Adjust the size as needed
                                    >
                                        <g>
                                            <g>
                                                <path
                                                    d="M671.504,577.829l110.485-432.609H902.86v-68H729.174L703.128,179.2L0,178.697l74.753,399.129h596.751V577.829z M685.766,247.188l-67.077,262.64H131.199L81.928,246.756L685.766,247.188z"
                                                ></path>
                                                <path
                                                    d="M578.418,825.641c59.961,0,108.743-48.783,108.743-108.744s-48.782-108.742-108.743-108.742H168.717c-59.961,0-108.744,48.781-108.744,108.742s48.782,108.744,108.744,108.744c59.962,0,108.743-48.783,108.743-108.744c0-14.4-2.821-28.152-7.927-40.742h208.069c-5.107,12.59-7.928,26.342-7.928,40.742C469.675,776.858,518.457,825.641,578.418,825.641z M209.46,716.897c0,22.467-18.277,40.744-40.743,40.744c-22.466,0-40.744-18.277-40.744-40.744c0-22.465,18.277-40.742,40.744-40.742C191.183,676.155,209.46,694.432,209.46,716.897z M619.162,716.897c0,22.467-18.277,40.744-40.743,40.744s-40.743-18.277-40.743-40.744c0-22.465,18.277-40.742,40.743-40.742S619.162,694.432,619.162,716.897z"
                                                ></path>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                            </td>

                            <td className="px-4 py-1 justify-center items-center">
                                <button
                                    className="bg-gray-200 hover:bg-gray-300 text-gray-800 rounded transition duration-200 ease-in-out shadow-sm px-4 py-1 justify-center items-center"
                                    onClick={() => handleReplyClick(item)}
                                    aria-label="Responder"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-6 h-6 mr-1" // Adjust size if necessary
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                        />
                                    </svg>
                                </button>
                            </td>


                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-end mt-4">
                {totalPages > 1 && (
                    <nav>
                        <ul className="inline-flex">
                            {pageNumbers.map((number) => (
                                <li key={number}
                                    className={currentPage === number ? "px-2 py-1 bg-green-cl text-white rounded-md mx-1" : "px-2 py-1 bg-gray-200 text-gray-700 rounded-md mx-1"}>
                                    <button onClick={() => handlePageChange(number)} className="focus:outline-none">
                                        {number}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                )}
            </div>

            {showModal && selectedReply && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-75 z-50 flex justify-center items-center">
                    <div className="rounded shadow bg-gray-100 p-5 relative">
                        <button className="absolute top-0 right-0 p-2" onClick={() => setShowModal(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <h2 className="mb-3 text-center text-2xl font-bold">Respostas</h2>
                        <table className="table-auto w-full border">
                            <thead className=''>
                            <tr className="bg-green-cl">
                                <th className="px-4 py-2 text-left text-gray-100">Campo</th>
                                <th className="px-4 py-2 text-left text-gray-100">Resposta</th>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedReply.replyFields.map((content) => (
                                <tr key={content.label} className="border-t border-gray-200 justify-center text-center">
                                    <td className="px-4 py-1">{content.label}</td>
                                    <td className="px-4 py-1">{content.answer}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            )}
            {isItemsModalOpen && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-75 flex items-center justify-center z-50 transition-opacity duration-300">
                    <div className="bg-white p-6 rounded-lg shadow-lg relative max-w-md w-full">
                        <button
                            onClick={() => setIsItemsModalOpen(false)}
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 transition-colors"
                            aria-label="Close Modal"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <h2 className="text-2xl font-bold text-center mb-4">Itens Adquiridos</h2>
                        <ul className="list-disc list-inside space-y-2">
                            {Object.entries(itemsToShow).length > 0 ? (
                                Object.entries(itemsToShow)
                                    .filter(([item, quantity]) => quantity > 0) // Filter items with quantity > 0
                                    .map(([item, quantity]) => (
                                        <li key={item} className="p-2 bg-gray-100 rounded hover:bg-gray-200 transition-colors">
                                            {`${item}: ${quantity}`}
                                        </li>
                                    ))
                            ) : (
                                <li className="text-center text-gray-500">Nenhum item encontrado.</li>
                            )}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    )
}




export default Replies





